import { defineStore } from 'pinia';

// Represents the buying options available for a product in Magento
export enum BuyingOptionEnum {
  FOR_DELIVERY = 'DEFAULT',
  IN_BOND = 'IN_BOND'
}

// Represents the buying options in add to cart event
export enum BuyingOptionEventEnum {
  FOR_DELIVERY = 'default',
  IN_BOND = 'inbond'
}

// Represents the buying options in Algolia purchase mode facet
export enum BuyingOptionAlgoliaEnum {
  FOR_DELIVERY = 'Delivery',
  IN_BOND = 'In Bond'
};

export interface BuyingOptionInterface {
  type: `${BuyingOptionEnum}`;
  label: string;
  isSelected: boolean;
  isAvailable: boolean;
}

interface BuyingOptionStoreInterface {
  mode: BuyingOptionInterface;
  modes: BuyingOptionInterface[];
}

export const DEFAULT_BUYING_OPTIONS: BuyingOptionInterface[] = [{
  type: BuyingOptionEnum.FOR_DELIVERY,
  label: 'Delivery',
  isSelected: true,
  isAvailable: true,
}, {
  type: BuyingOptionEnum.IN_BOND,
  label: 'Storage In Bond',
  isSelected: false,
  isAvailable: true,
}];

export const useBuyingOption = defineStore('buyingOption', {
  state: () => <BuyingOptionStoreInterface>({
    modes: DEFAULT_BUYING_OPTIONS,
  }),
  getters: {
    isInBondSelected(state: BuyingOptionStoreInterface): boolean {
      return state.modes.some((option) => option.isSelected && option.type === BuyingOptionEnum.IN_BOND);
    },
    isForDeliverySelected(state: BuyingOptionStoreInterface): boolean {
      return state.modes.some((option) => option.isSelected && option.type === BuyingOptionEnum.FOR_DELIVERY);
    },
    selectedMode(state: BuyingOptionStoreInterface): BuyingOptionInterface {
      return state.modes.find((option) => option.isSelected);
    },
    getModeByType(state: BuyingOptionStoreInterface): (type: string) => BuyingOptionInterface {
      return (type: string) => state.modes.find((option) => option.type === type);
    },
    getAvailable(state: BuyingOptionStoreInterface): BuyingOptionInterface | undefined {
      const selectedMode = this.selectedMode;

      if (selectedMode.isAvailable) {
        return selectedMode;
      }

      return state.modes.find((option) => option.isAvailable);
    },
    getUnselectedMode(state: BuyingOptionStoreInterface): BuyingOptionInterface {
      return state.modes.find((option) => !option.isSelected);
    },
  },
  actions: {
    switchModeByType(type: string) {
      const option = this.getModeByType(type);

      if (option?.isAvailable) {
        this.toggleModeByType(type);
      }
    },
    updateAvailability(
      type: string,
      isAvailable: boolean,
    ) {
      this.modes = this.modes.map((option: BuyingOptionInterface) => {
        if (option.type === type) {
          return { ...option, isAvailable };
        }

        return option;
      });
    },
    toggleModeByType(type: string) {
      this.modes = this.modes.map((option: BuyingOptionInterface) => ({
        ...option,
        isSelected: type === option.type,
      }));
    },
    switchToAvailableMode() {
      const available = this.getAvailable;

      if (available) {
        this.toggleModeByType(available.type);
      }
    },
  },
});
