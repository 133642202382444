const formatCurrency = (value: number | string, locale: string, options: Intl.NumberFormatOptions): string => {
  if (typeof value === 'string') {
    // eslint-disable-next-line no-param-reassign
    value = Number(value);
  }
  // eslint-disable-next-line no-param-reassign

  return new Intl.NumberFormat(locale, { style: 'currency', ...options }).format(value);
};

const unformatCurrency = (value: string, locale: string): number => {
  // @ts-ignore
  const parts = new Intl.NumberFormat(locale, { style: 'currency', currency: 'GBP' }).formatToParts(1.1);
  const decimalSeparator = parts.find(part => part.type === 'decimal').value;

  // Remove all non-digits except the localised decimal separator, then convert the decimal separator to the dot.
  return parseFloat(value.replace(new RegExp(`[^\\d${decimalSeparator}]`, 'g'), '').replace(decimalSeparator, '.'));
};

export {
  formatCurrency,
  unformatCurrency,
};
