

























import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';

interface Store {
  id: number;
  name: string;
  address: string;
  workingHours: string;
  latitude: number;
  longitude: number;
  showDetails?: boolean;
}

export default defineComponent({
  name: 'UserShippingPickupLocationItem',
  props: {
    pickupLocation: {
      type: Object as PropType<Store>,
      required: true,
    },
  },
  setup(props) {
    const getDirectionsLink = (latitude: number, longitude: number): string => {
      return `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    }

    const getMapLink = (latitude: number, longitude: number, zoom: number = 15): string => {
      return `https://www.google.com/maps/@?api=1&map_action=map&zoom=${zoom}&center=${latitude},${longitude}`;
    }

    const store = ref<Store>(props.pickupLocation);

    return {
      store,
      getDirectionsLink,
      getMapLink,
    }
  }
});
