import axios from 'axios';
import { defineStore } from 'pinia';
import { ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';

export type Content = any;

export const useContentStore = defineStore('content-store', () => {
  const { $amplience } = useContext();
  const cache = ref<Content>({});

  const getUrl = (key = 'id', value: string, previewUrl?: string): string => (
    `${previewUrl || $amplience.baseUrl}/content/${key}/${value}`
  );

  const search = async (key = 'id', value: string, previewUrl?: string): Promise<Content | null> => {
    const cacheKey = `${value}_${previewUrl || ''}`;

    try {
      if (!cache.value[cacheKey]) {
        const url = getUrl(key === 'url' ? 'key' : key, value, previewUrl);
        const { data } = await axios.get(url, {
          params: {
            depth: 'all',
            format: 'inlined',
          }
        });

        cache.value = {
          ...cache.value,
          [cacheKey]: data?.content || null,
        };
      }
    } catch (e) {
      Logger.error('content/search/amplience-error', `${key}: ${value}`, e.message);
    }

    return cache.value[cacheKey] || null;
  };

  return {
    search,
  };
});
