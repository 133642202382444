import axios from 'axios';
import { RoutableInterface, UrlRewriteEntityTypeEnum } from '~/modules/GraphQL/types';
import { usePageStore } from '~/stores/page';
import { Context } from '@nuxt/types';
import { UseContextReturn } from '~/types/core';
import { Logger } from '~/helpers/logger';
import { getPageType } from '@amplience/helpers/getPageType';

export const useUrlResolver = () => {
  const pageStore = usePageStore();

  const setHeader = (context: Context | UseContextReturn, key: string, value: string) => {
    if (context.res) {
      context.res.setHeader(key, value);
    }
  };

  const clearUrl = (context: Context | UseContextReturn, path: string) => (
    path
      .replace(/[a-z]+\/[cp|]\//gi, '')
      .replace(`/${context.i18n.locale}`, '')
      .replace(/^\/(.*)$/, '$1')
  );

  const resolveAmpl = async (context: Context | UseContextReturn, path: string): Promise<RoutableInterface> => {
    try {
      const vse = context.$cookies.get('bbr-vse');
      const previewUrl = vse ? `https://${vse}` : '';
      const apiURL = `${previewUrl || context.$amplience.baseUrl}/content/key/${path}`;

      const { data } = await axios.get(apiURL, {
        params: {
          depth: 'all',
          format: 'inlined',
        }
      });

      const content = data?.content || null;

      Logger.debug('middleware/url-resolver/amplience-result', { content });

      const schema = content?._meta?.schema || '';
      const pageType = getPageType(schema);

      if (!pageType) {
        // eslint-disable-next-line max-len
        Logger.info(`A page renderer for this schema ${schema} is not defined. Define it in config.ts:schemaPages in the root of the amplience module.`);
      }

      if (content && pageType && !content.isDraft) {
        setHeader(context, 'Surrogate-Control', context.$config.fastly.surrogateControl);
        setHeader(context, 'X-Magento-Tags', `CP_${path} CONTENT_PAGE`);

        return {
          type: pageType,
          identifier: path,
          redirect_code: 0,
        };
      }
    } catch (e) {
      Logger.error('middleware/url-resolver/amplience-error', path, e.message);
    }

    return null;
  };

  const resolveAdobe = async (context: Context | UseContextReturn, path: string): Promise<RoutableInterface> => {
    try {
      const { data, errors } = await context.app.$vsf.$magento.api.route(path);

      Logger.debug('middleware/url-resolver/magento-result', { data, errors });

      const route = data?.route as RoutableInterface ?? null;

      if (route?.type === 'CATEGORY') {
        setHeader(context, 'Surrogate-Control', context.$config.fastly.surrogateControl);
        setHeader(context, 'X-Magento-Tags', `CAT_${route.uid} CATEGORY`);

        if (route.is_biddable_category) {
          setHeader(context, 'X-Magento-Tags', `CAT_${route.uid} CATEGORY CAT_BIDDABLE`);
        }

        if (route.is_dynamic_category) {
          setHeader(context, 'Surrogate-Control', context.$config.fastly.surrogateControlDynamicCategory);
          setHeader(context, 'X-Magento-Tags', `CAT_${route.uid} CATEGORY CAT_DYNAMIC`);
        }
      } else if (route?.type === 'PRODUCT') {
        setHeader(context, 'Surrogate-Control', context.$config.fastly.surrogateControl);
        setHeader(context, 'X-Magento-Tags', `P_${route.sku} PRODUCT`);
      }

      return route;
    } catch (e) {
      Logger.info('middleware/url-resolver/magento-error', path, e);
    }

    return null;
  };

  const resolve = async (context: Context | UseContextReturn, path: string): Promise<void> => {
    const formattedPath = clearUrl(context, path);
    let results: RoutableInterface | null = await resolveAdobe(context, formattedPath);

    if (results?.redirect_code === 301 || results?.redirect_code === 302) {
      context.redirect(`/${results.relative_url}`);
    } else {
      // If the results are found and the type is CATEGORY and the path is an Algolia category,
      // we change the type to ALGOLIA_CATEGORY
      if (results?.type === 'CATEGORY' && !!context?.$algoliaSearch?.enabled) {
        results = {
          ...results,
          type: 'ALGOLIA_CATEGORY' as UrlRewriteEntityTypeEnum,
        };
      }

      // If the results are not found or the type is CMS_PAGE,
      // we try to resolve the URL using Amplience,
      if (!results || results.type === 'CMS_PAGE' || results.type === 'CUSTOM') {
        results = await resolveAmpl(context, formattedPath);
      }

      // If the results are not found, we throw a 404 error
      if (!results) context.error({ statusCode: 404 });

      pageStore.$patch((state) => {
        state.routeData = results;
      });
    }
  }

  return {
    resolve,
  };
}
