import { render, staticRenderFns } from "./EmailSignUpForm.vue?vue&type=template&id=ddfad132&scoped=true"
import script from "./EmailSignUpForm.vue?vue&type=script&lang=ts"
export * from "./EmailSignUpForm.vue?vue&type=script&lang=ts"
import style0 from "./EmailSignUpForm.vue?vue&type=style&index=0&id=ddfad132&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddfad132",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RenderRichText: require('/var/www/modules/amplience/components/render/RichText.vue').default})
