import { render, staticRenderFns } from "./FooterSlim.vue?vue&type=template&id=0f14adc8"
import script from "./FooterSlim.vue?vue&type=script&lang=ts"
export * from "./FooterSlim.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SimpleFooter: require('/var/www/modules/amplience/components/content-type/SimpleFooter.vue').default})
