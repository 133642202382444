import { render, staticRenderFns } from "./QuickAccessModal.vue?vue&type=template&id=a710be1c&scoped=true"
import script from "./QuickAccessModal.vue?vue&type=script&lang=ts"
export * from "./QuickAccessModal.vue?vue&type=script&lang=ts"
import style0 from "./QuickAccessModal.vue?vue&type=style&index=0&id=a710be1c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a710be1c",
  null
  
)

export default component.exports