import { ref } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import {
  BBRProductAttributes,
  getAttributeValue,
} from '@theme/modules/catalog/getters/productGetters';
import {
  AddToCartNotificationInterface,
  ProductAttributeInterface,
  AddedItemInterface,
} from '@theme/modules/catalog/types';
import { useUiState } from '@theme/modules/catalog/composables/useUiState';
import { SimpleProduct } from '~/modules/GraphQL/types';

export function useAddToCartNotification() {
  const addedProduct = ref<AddToCartNotificationInterface | null>(null);
  const { isAddToCartNotification, toggleAddToCartNotification } = useUiState();

  const setAddedProduct = (notification: AddToCartNotificationInterface | null) => {
    addedProduct.value = notification;
  };

  const resetAddedProduct = () => {
    setAddedProduct(null);
  };

  const displayAddToCartNotification = ({
    isAdded,
    isCase,
    product,
    variant,
    quantity,
    addedItem,
  }: {
    isAdded: boolean;
    isCase: boolean;
    product: Product,
    variant: SimpleProduct | null,
    quantity: number,
    addedItem: AddedItemInterface,
  }) => {
    if (!isAdded) {
      return;
    }

    const attributes = [] as ProductAttributeInterface[];

    resetAddedProduct();

    // Add bottle volume and bottle order unit to attributes
    if (variant) {
      const bottleVolume = getAttributeValue(variant, BBRProductAttributes.BOTTLE_VOLUME);
      const bottleOrderUnit = getAttributeValue(variant, BBRProductAttributes.BOTTLE_ORDER_UNIT);

      if (bottleVolume && bottleOrderUnit) {
        attributes.push({
          label: bottleOrderUnit,
          value: bottleVolume,
        });
      }
    }

    // TODO: totalEntryPrice is not available in Add To Cart response
    const price = addedItem?.totalEntryPrice ? addedItem?.totalEntryPrice.value / addedItem.quantity : null;

    // Set notification data
    setAddedProduct({
      product: product,
      variant: variant || null,
      qty: quantity || 1,
      isCase: !!(isCase) || false,
      attributes,
      price,
    });

    // Show notification sidebar
    toggleAddToCartNotification();
  }

  return {
    addedProduct,
    isAddToCartNotification,
    toggleAddToCartNotification,
    setAddedProduct,
    resetAddedProduct,
    displayAddToCartNotification,
  }
}

export default useAddToCartNotification;
