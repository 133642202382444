















import {
  defineComponent,
  PropType,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { useCtaPartial, LinkType } from '@theme/composables';

export default defineComponent({
  name: 'CtaPartial',
  components: {
    SfButton,
  },
  props: {
    // This option controls the behavior and data that is used for the button
    linkType: {
      type: String as (
        PropType<LinkType.PRODUCT_SKU | LinkType.CATEGORY_ID | LinkType.AMPLIENCE_ID | LinkType.EXTERNAL_URL>
      ),
      default: LinkType.EXTERNAL_URL,
    },
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: ''
    },
    // Button type for styling, accepted values: Primary, Secondary
    type: {
      type: String as PropType<'Primary' | 'Secondary'>,
      default: 'Primary'
    },
    newWindow: {
      type: Boolean,
      default: false
    },
    noFollow: {
      type: Boolean,
      default: false
    },
    trackingQuery: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { fetchUrl } = useCtaPartial(props.url);

    const ctaURL = ref<string>(props.linkType === LinkType.EXTERNAL_URL ? props.url : '');

    useFetch(async () => {
      if (props.linkType !== LinkType.EXTERNAL_URL) {
        const url = await fetchUrl(props.linkType);
        ctaURL.value = props.trackingQuery ? `${url}${props.trackingQuery}` : url;
      }
    });

    return {
      ctaURL,
    };
  },
});
