import { Plugin } from '@nuxt/types';
import { Product } from '~/modules/catalog/product/types';
import { types, FamilyType } from '~/bbrTheme/modules/catalog/stores/product';
import {
  getAttributeValue,
  getRoundelUrlCode,
  BBRProductAttributes,
} from '@theme/modules/catalog/getters/productGetters';

interface GetAmplienceImageUrl {
  $getAmplienceImageUrl(product: Product, deskType?: string): string;
  $getRawAmplienceImageUrl(mainImage: string, familyType: string, deskType?: string, roundelCode?: string): string;
}

declare module 'vue/types/vue' {
  interface Vue extends GetAmplienceImageUrl {
  }
}

declare module '@nuxt/types' {
  interface Context extends GetAmplienceImageUrl {
  }
}

// Generate the Amplience image url for the product image in the PLP, PDP pages, etc.
const generateUrl = (
  baseUrl,
  sku = 'default',
  family = FamilyType.Wines,
  deskType = 'deskPDP',
  roundelCode?: string,
) => {
  // Get the fallback image for the family type if wrong family type is passed
  const fallbackImage = types[family] || types[FamilyType.Wines];

  let url = baseUrl;

  if (!url) {
    throw new Error('Amplience media set url is not defined');
  }

  if (url.endsWith('/')) {
    url = url.slice(0, -1)
  }

  const deskTypeVar = `${deskType === 'none' ? '' : `&$${deskType}$`}`;

  // Return the url with the sku and family type
  // eslint-disable-next-line max-len
  return `${url}/${sku}-ms?img404=${fallbackImage.defaultImg}${roundelCode ? `&layer1=[${roundelCode}]` : ''}${deskTypeVar}&fmt=auto`;
}

const plugin: Plugin = (context, inject) => {
  const {
    env: {
      VSF_AMPLIENCE_MEDIA_SET_URL
    }
  } = context;

  inject('getAmplienceImageUrl', (product: Product, deskType = 'deskPDP'): string => {
    const mainImage = getAttributeValue(product, BBRProductAttributes.MAIN_IMAGE);
    const roundelCode = getRoundelUrlCode(product);
    const familyType = getAttributeValue(product, BBRProductAttributes.FAMILY_TYPE) as FamilyType;

    return generateUrl(
      VSF_AMPLIENCE_MEDIA_SET_URL,
      mainImage || 'default',
      familyType || FamilyType.Wines,
      deskType,
      roundelCode,
    );
  });

  inject('getRawAmplienceImageUrl', (
    mainImage: string,
    familyType: string,
    deskType = 'deskPDP',
    roundelCode?: string,
  ): string => {
    return generateUrl(
      VSF_AMPLIENCE_MEDIA_SET_URL,
      mainImage || 'default',
      familyType as FamilyType || FamilyType.Wines,
      deskType,
      roundelCode,
    );
  });
};

export default plugin;
