


















import {
  useContext,
  useFetch,
  defineComponent,
} from '@nuxtjs/composition-api';
import { useContent } from '~/modules/amplience/composables/useContent';

export default defineComponent({
  name: 'HomePage',
  middleware: [
    'homepage-resolver',
  ],
  setup() {
    const { $config } = useContext();
    const { search, data } = useContent();

    useFetch(async () => {
      await search('home', 'url');
    });

    return {
      data,
      baseURL: $config.baseURL,
    };
  }
});
