import { render, staticRenderFns } from "./TouchTarget.vue?vue&type=template&id=9b54df86&scoped=true"
import script from "./TouchTarget.vue?vue&type=script&lang=ts"
export * from "./TouchTarget.vue?vue&type=script&lang=ts"
import style0 from "./TouchTarget.vue?vue&type=style&index=0&id=9b54df86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b54df86",
  null
  
)

export default component.exports