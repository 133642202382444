import { Plugin } from '@nuxt/types';
import type { AmplienceModuleOptions } from '@amplience/types';

declare module 'vue/types/vue' {
  interface Vue {
    $amplience: AmplienceModuleOptions;
  }
}

declare module '@nuxt/types' {
  interface Context {
    $amplience: AmplienceModuleOptions;
  }
}

const ampliencePlugin: Plugin = (_context, inject) => {
  const options = JSON.parse('{"baseUrl":"https://1o6pu8rbwadqi1h1g5kiosiurx.staging.bigcontent.io","headerDeliveryKey":"prodsupp-header","footerDeliveryKey":"prodsupp-footer","globalHeaderDeliveryKey":"prodsupp-global-header","globalFooterDeliveryKey":"prodsupp-global-footer","mediaSetUrl":"https://media.bbr.com/s/bbr/","mediaItemUrl":"https://media.bbr.com/i/bbr/"}') as AmplienceModuleOptions;
  const {
    baseUrl,
    headerDeliveryKey,
    footerDeliveryKey,
    globalHeaderDeliveryKey,
    globalFooterDeliveryKey,
    mediaSetUrl,
    mediaItemUrl,
  } = options;

  inject('amplience', {
    baseUrl,
    headerDeliveryKey,
    footerDeliveryKey,
    globalHeaderDeliveryKey,
    globalFooterDeliveryKey,
    mediaSetUrl,
    mediaItemUrl,
  });
};

export default ampliencePlugin;
