import gql from 'graphql-tag';

export const categoryUrlGql = gql(`
  query getCategories ($filters: CategoryFilterInput) {
    categories(filters: $filters) {
      items {
        url_path
      }
    }
  }
`);
