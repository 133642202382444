import { useContext } from '@nuxtjs/composition-api';
import { useProduct } from '~/composables';
import { useContent } from '@amplience/composables/useContent';
import { productUrlGql } from '@theme/composables/useCtaPartial/queries/productUrl.gql';
import { categoryUrlGql } from '@theme/composables/useCtaPartial/queries/categoryUrl.gql';

export enum LinkType {
  PRODUCT_SKU = 'product_sku',
  CATEGORY_ID = 'category_id',
  AMPLIENCE_ID = 'amplience_content_item_id',
  EXTERNAL_URL = 'external_url'
}

export interface UseCtaPartialInterface {
  fetchUrl(linkType: string): Promise<string>;
}

export function useCtaPartial(id: string) : UseCtaPartialInterface {
  const { localePath, app } = useContext();
  const { getProductPath } = useProduct();
  const { search } = useContent();

  const getProductUrl = async (): Promise<string> => {
    const { data } = await app.$vsf.$magento.api.customProductDetail({
      query: productUrlGql,
      variables: { filter: { sku: { eq: id } } },
    });

    const product = data?.products?.items?.[0] || null;

    if (product) {
      return localePath(getProductPath(product));
    }

    return '';
  };

  const getCategoryUrl = async (): Promise<string> => {
    const { data } = await app.$vsf.$magento.api.customCategoryDetail({
      query: categoryUrlGql,
      variables: { filters: { ids: { eq: id } } },
    });

    const category = data?.categories?.items?.[0] || null;

    if (category) {
      return localePath(category.url_path);
    }

    return '';
  };

  const getContentUrl = async (): Promise<string> => {
    const content = await search(id, 'id');

    return content?._meta?.deliveryKey ?? '';
  };

  const urlFetchers: {
    [key: string]: () => Promise<string>;
  } = {
    [LinkType.PRODUCT_SKU]: getProductUrl,
    [LinkType.CATEGORY_ID]: getCategoryUrl,
    [LinkType.AMPLIENCE_ID]: getContentUrl,
  };

  const fetchUrl = async (linkType: string): Promise<string> => {
    const fetcher = urlFetchers[linkType];

    return fetcher ? await fetcher() : '';
  };

  return {
    fetchUrl,
  };
}

export default useCtaPartial;
