
























































import {
  computed,
  defineComponent,
  PropType,
  ref,
} from '@nuxtjs/composition-api';
import { SfButton, SfHeading, SfRadio } from '@storefront-ui/vue';
import type { Aggregation } from '~/modules/GraphQL/types';
import { useCategoryFilters } from '~/bbrTheme/modules/catalog/stores/categoryFilters';
import { storeToRefs } from 'pinia';

export default defineComponent({
  components: {
    SfButton,
    SfHeading,
    SfRadio,
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useCategoryFilters();
    const { selectedFiltersOptions } = storeToRefs(store);
    const selected = computed((): any => ((id: string, value: string) => {
      if (selectedFiltersOptions.value.find((option) => option.id === id && option.value === value)) {
        return value;
      }
    }));
    const isOpened = ref<boolean>(false);

    return { selected, isOpened };
  },
});
