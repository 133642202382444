import { Middleware } from '@nuxt/types';
import { useUrlResolver } from '@theme/composables/useUrlResolver';

const urlResolverMiddleware: Middleware = async (context) => {
  const { path } = context.route;
  const { resolve } = useUrlResolver();

  await resolve(context, path);
};

export default urlResolverMiddleware;
