import { render, staticRenderFns } from "./TermsLink.vue?vue&type=template&id=5d7f335f&scoped=true"
import script from "./TermsLink.vue?vue&type=script&lang=ts"
export * from "./TermsLink.vue?vue&type=script&lang=ts"
import style0 from "./TermsLink.vue?vue&type=style&index=0&id=5d7f335f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d7f335f",
  null
  
)

export default component.exports