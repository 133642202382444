import { gql } from 'graphql-tag';

export const productUrlGql = gql(`
  query productUrl($filter: ProductAttributeFilterInput) {
    products(filter: $filter) {
      items {
        url_key
        url_rewrites {
          url
        }
      }
    }
  }
`);
