import { Plugin } from '@nuxt/types';

interface GainLoss {
  $getGainLossClass(changeValue: number): string;
  $formatGainLoss(changeValue: number, changeValuePercentage: number): string;
  $formatGainLossPercentage(changeValue: number, changeValuePercentage: number): string;
}

declare module 'vue/types/vue' {
  interface Vue extends GainLoss {
  }
}

declare module '@nuxt/types' {
  interface Context extends GainLoss {
  }
}

const plugin: Plugin = (context, inject) => {
  const { $fc, $fpcent } = context;

  /**
   * @returns CSS class of gain / loss
   */
  inject('getGainLossClass', (changeValue: number): string =>
    (changeValue > 0 ? 'positive' : changeValue < 0 ? 'negative' : ''));

  /**
   * @returns formatted string of gain / loss like '£55.00 +12%'
   */
  inject('formatGainLoss', (changeValue: number, changeValuePercentage: number): string => {
    const value = $fc(changeValue);
    const operator = changeValue > 0 ? '+' : '';
    const percent = $fpcent(changeValuePercentage, '', {
      style: 'percent',
      maximumFractionDigits: 0,
    });

    return `${value} ${operator}${percent}`;
  });

  /**
   * @returns formatted string of gain / loss percentage like '+12%'
   */
  inject('formatGainLossPercentage', (changeValue: number, changeValuePercentage: number): string => {
    const operator = changeValue > 0 ? '+' : '';
    const percent = $fpcent(changeValuePercentage, '', {
      style: 'percent',
      maximumFractionDigits: 0,
    });

    return `${operator}${percent}`;
  });
};

export default plugin;
