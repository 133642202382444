import { ref, useContext, readonly } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type {
  RelatedProduct,
  UseRelatedProductsErrors,
  UseRelatedProductsInterface,
  UseRelatedProductsSearchParams,
} from './useRelatedProducts';
import { RelatedProductQuery } from '~/modules/GraphQL/types';
import { BBRProductAttributes } from '@theme/modules/catalog/getters/productGetters';

const attributeCodes = [
  BBRProductAttributes.MAIN_IMAGE,
  BBRProductAttributes.FAMILY_TYPE,
  BBRProductAttributes.EVENT_TYPE,
  BBRProductAttributes.REGION,
  BBRProductAttributes.STYLE_TEMP,
  BBRProductAttributes.BOTTLE_ORDER_UNIT,
  BBRProductAttributes.BOTTLE_VOLUME,
  BBRProductAttributes.CASE_ORDER_UNIT,
  BBRProductAttributes.DUTIABLE_VOLUME_ML,
];

/**
 * Allows searching for related products
 * with params for sort, filter and pagination.
 *
 * See the {@link UseRelatedProductsInterface} for a list of methods and values available in this composable.
 */
export function useRelatedProducts(): UseRelatedProductsInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseRelatedProductsErrors>({
    search: null,
  });

  const search = async (params: UseRelatedProductsSearchParams): Promise<RelatedProduct[]> => {
    const { customQuery, ...searchParams } = params;

    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] Load related products based on ', { searchParams });

      if (!searchParams.attributeCodes?.length) {
        searchParams.attributeCodes = attributeCodes;
      }

      const { data } = await app.$vsf.$magento.api.relatedProduct(
        searchParams as GetProductSearchParams,
        params?.customQuery || { relatedProduct: 'relatedProduct' },
        params?.customHeaders,
      );

      Logger.debug('[Result] Related products:', { data });

      results = (data as RelatedProductQuery)?.products?.items[0]?.related_products;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    search,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useRelatedProducts';
export default useRelatedProducts;
