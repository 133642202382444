import { defineStore } from 'pinia';
import { ProductCustomAttributes, ProductInterface } from '~/modules/GraphQL/types';
import {
  getAttributeValue,
  getFilteredAttributes,
  getStockDataQty,
  isGiftCard
} from '~/bbrTheme/modules/catalog/getters/productGetters';
import { useContext } from '@nuxtjs/composition-api';

export enum FamilyType {
  Accessories = 'Accessories',
  AssortmentMixedCases = 'Assortment Mixed Cases',
  Books = 'Books',
  BundleMixedCase = 'Bundle Mixed Case',
  Events = 'Ticketed Events',
  Glassware = 'Glassware',
  OtherDrinks = 'Other Drinks',
  Spirits = 'Spirits',
  Wines = 'Wines',
}

export enum Image404 {
  Accessories = 'Default_Wine',
  AssortmentMixedCases = 'Default_Wine',
  Books = 'Default_Books',
  BundleMixedCase = 'Default_Wine',
  Events = 'Default_Events',
  Glassware = 'Default_Wine',
  OtherDrinks = 'Default_Spirits',
  Spirits = 'Default_Spirits',
  Wines = 'Default_Wine',
}

export interface ProductStoreInterface {
  product: ProductInterface;
  familyType: FamilyType;
  code: string;
  schemaID: string;
  attributes: ProductCustomAttributes[];
  content: any;
}

export const types = {
  [FamilyType.Accessories]: {
    code: 'accessories',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.Accessories,
  },
  [FamilyType.AssortmentMixedCases]: {
    code: 'assortment_mixed_cases',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.AssortmentMixedCases,
  },
  [FamilyType.Books]: {
    code: 'books',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.Books,
  },
  [FamilyType.BundleMixedCase]: {
    code: 'bundle_mixed_case',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.BundleMixedCase,
  },
  [FamilyType.Events]: {
    code: 'ticketed_events',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.Events,
  },
  [FamilyType.Glassware]: {
    code: 'glassware',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.Glassware,
  },
  [FamilyType.OtherDrinks]: {
    code: 'other_drinks',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.OtherDrinks,
  },
  [FamilyType.Spirits]: {
    code: 'spirits',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.Spirits,
  },
  [FamilyType.Wines]: {
    code: 'wines',
    schemaID: 'https://www.bbr.com/content-type/product-body',
    defaultImg: Image404.Wines,
  },
};

export const allowedAttributeList = {
  [FamilyType.Accessories]: [],
  [FamilyType.AssortmentMixedCases]: [],
  [FamilyType.Books]: [],
  [FamilyType.BundleMixedCase]: [],
  [FamilyType.Events]: [
    'event_start_date',
    'event_start_time',
  ],
  [FamilyType.Glassware]: [],
  [FamilyType.OtherDrinks]: [
    'alcohol',
    'style_temp',
  ],
  [FamilyType.Spirits]: [
    'alcohol',
    'style_temp',
  ],
  [FamilyType.Wines]: [
    'colour',
    'sweetness',
    'body',
    'grape_list',
  ],
}

const allowedAttributeListDetailed = {
  [FamilyType.Accessories]: [],
  [FamilyType.AssortmentMixedCases]: [
    'colour',
    'sweetness',
    'alcohol',
    'maturity',
    'grape_list',
    'property',
  ],
  [FamilyType.Books]: [],
  [FamilyType.BundleMixedCase]: [],
  [FamilyType.Events]: [
    'event_start_date',
    'event_start_time',
    'events_location_data',
    'event_max_tickets',
    'event_dress_code',
    'event_hosts',
  ],
  [FamilyType.Glassware]: [],
  [FamilyType.OtherDrinks]: [
    'style_temp',
    'sweetness',
    'alcohol',
    'main_ingredients',
    'region',
    'property',
  ],
  [FamilyType.Spirits]: [
    'style_temp',
    'sweetness',
    'alcohol',
    'main_ingredients',
    'region',
    'property',
  ],
  [FamilyType.Wines]: [
    'colour',
    'sweetness',
    'vintage',
    'alcohol',
    'maturity',
    'grape_list',
    'body',
    'property',
  ],
};

const formatterQuickAttributes = {
  alcohol: (value) => `${value}% alcohol`,
}

const formatterDetailedAttributes = {
  event_max_tickets: (value) => `Maximum ${value} tickets per customer`,
}

// It was requested to hardcode to labels data temporarily
// As it is not aligned between PIM data and designs
const labelsFormatter = {
  event_start_date: 'Date',
  event_start_time: 'Time',
  event_max_tickets: 'Ticket Limit',
  event_hosts: 'Host',
  events_location_data: 'Location',
}

export const useProductDetails = defineStore('product', {
  state: () => <ProductStoreInterface>({
    product: {},
    familyType: FamilyType.Wines,
    code: '',
    schemaID: '',
    attributes: [],
    content: {},
  }),
  getters: {
    isAccessory(state) {
      return state.familyType === FamilyType.Accessories;
    },
    isAssortmentMixedCases(state) {
      return state.familyType === FamilyType.AssortmentMixedCases;
    },
    isBook(state) {
      return state.familyType === FamilyType.Books;
    },
    isBundleMixedCase(state) {
      return state.familyType === FamilyType.BundleMixedCase;
    },
    isEvent(state) {
      return state.familyType === FamilyType.Events;
    },
    isGlassware(state) {
      return state.familyType === FamilyType.Glassware;
    },
    isOtherDrinks(state) {
      return state.familyType === FamilyType.OtherDrinks;
    },
    isSpirit(state) {
      return state.familyType === FamilyType.Spirits;
    },
    isWine(state) {
      return state.familyType === FamilyType.Wines;
    },
    allowedAttributes(state): ProductCustomAttributes[] {
      return getFilteredAttributes(
        state.attributes,
        allowedAttributeList[this.familyType] || [],
      );
    },
    allowedAttributesDetailed(state): ProductCustomAttributes[] {
      return getFilteredAttributes(
        state.attributes,
        allowedAttributeListDetailed[this.familyType] || [],
      );
    },
    infoMessage(state): string | null {
      const { i18n } = useContext();
      const ticketLimit = state.attributes.find(attribute => attribute.code === 'event_max_tickets');

      // TODO: add UK only message here
      if (this.isEvent && ticketLimit) {
        return i18n.t('Due to limited quantities, this event has been restricted to {0} tickets per customer.', {
          0: ticketLimit.value
        }) as string;
      }

      return null;
    },
    maximumQtyAvailable(state): number {
      return this.isEvent
        ? Number(state.attributes.find(attribute => attribute.code === 'event_max_tickets').value)
        : getStockDataQty(state.product);
    },
    getFormattedAttributes(): Function {
      return (attributes: ProductCustomAttributes[], type: string): ProductCustomAttributes[] => {
        // An object that contains the attribute.code as key and expected formatted value to be returned
        const formatter = type === 'quick' ? formatterQuickAttributes : formatterDetailedAttributes;

        return attributes.map(attribute => {
          const formattedAttribute = formatter[attribute.code];

          return {
            ...attribute,
            value: formattedAttribute ? formattedAttribute(attribute.value) : attribute.value,
          };
        });
      }
    },
    getFormattedAttributeLabel(): Function {
      const { i18n } = useContext();

      return (attribute: ProductCustomAttributes): string => i18n.t(labelsFormatter[attribute.code]) as string
        || attribute.label;
    }
   },
  actions: {
    setProduct(product: ProductInterface) {
      this.product = product;
      this.attributes = product?.attributes_value ?? [];
      this.familyType = getAttributeValue(product, 'family_type') as FamilyType || FamilyType.Wines;
      // Gift voucher is an accessory family type, but we need to process it as a separate type of family for USP block
      this.code = isGiftCard(product) ? 'gift_voucher' : types[this.familyType].code;
      this.schemaID = types[this.familyType].schemaID;
    },
  },
});
