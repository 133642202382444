import { computed, readonly, ref, useContext, useRouter } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { URLParams, useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { Wishlist, clearWishlistResult } from '~/modules/GraphQL/types';
import type {
  UseWishlistAddItemParams,
  UseWishlistErrors,
  UseWishlistInterface,
  UseWishlistIsInWishlistParams,
  UseWishlistLoadParams,
  UseWishlistRemoveItemParams,
  UseWishlistAfterAddingWishlistItemToCartParams,
} from '~/modules/wishlist/composables/useWishlist/useWishlist';
import { useApi, useUiNotification, useMagentoConfiguration } from '~/composables';
import getWishlist from '@theme/customQueries/getWishlist.gql';
import clearWishlistGql from '@theme/customQueries/clearWishlist.gql';
import {
  createProductAttributeSortInput
} from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { useQueryParams } from '@theme/composables';

/**
 * Allows loading and manipulating wishlist of the current user.
 *
 * See the {@link UseWishlistInterface} for a list of methods and values available in this composable.
 */
export function useWishlist(): UseWishlistInterface {
  const wishlistStore = useWishlistStore();
  const { isWishlistEnabled } = useMagentoConfiguration();
  const { app, localeRoute } = useContext();
  const router = useRouter();
  const { send: sendNotification } = useUiNotification();
  const loading = ref(false);
  const { setQueryParams } = useQueryParams();
  const loadingOnRemove = ref(false);
  const pageSize = computed(() => wishlistStore.pageSize);
  const currentPage = computed(() => wishlistStore.currentPage);
  const sorting = computed(() => wishlistStore.sorting);
  const totalItems = computed(() => wishlistStore.totalItems);
  const attributeCodes = computed(() => wishlistStore.attributeCodes);
  const { mutate } = useApi();

  const calculateWishlistTotal = (wishlists: Wishlist[]) => {
    return wishlists.reduce((acc, current) => acc + (current?.items_count ?? 0), 0);
  };

  const error = ref<UseWishlistErrors>({
    addItem: null,
    removeItem: null,
    load: null,
    clear: null,
    loadItemsCount: null,
    afterAddingWishlistItemToCart: null,
  });

  const load = async (params?: UseWishlistLoadParams) => {
    Logger.debug('useWishlist/load');

    if (!isWishlistEnabled.value) {
      return wishlistStore.wishlist;
    }

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useWishlist.load params->', params);
      const apiState = app.$vsf.$magento.config.state;

      if (apiState.getCustomerToken()) {
        const { data } = await app.$vsf.$magento.api.customQuery({
          query: getWishlist,
          queryVariables: {
            ...params?.searchParams,
            attributeCodes: attributeCodes.value
          },
        })

        Logger.debug('[Result]:', { data });

        const loadedWishlist = (data as { customer: { wishlists: any[] } })?.customer?.wishlists ?? [];

        if (loadedWishlist[0]) {
          // @ts-ignore
          [wishlistStore.wishlist] = loadedWishlist;
        }
      }

      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('useWishlist/load', err);
    } finally {
      loading.value = false;
    }

    return wishlistStore.wishlist;
  };

  const isInWishlist = ({ product }: UseWishlistIsInWishlistParams) => {
    return product ? product.is_in_wishlist : false;
  };

  const setWishlist = (newWishlist: Wishlist) => {
    wishlistStore.wishlist = newWishlist;
    Logger.debug('useWishlist/setWishlist', newWishlist);
  };

  const removeItem = async ({ product, customQuery, customHeaders }: UseWishlistRemoveItemParams) => {
    Logger.debug('useWishlist/removeItem', product);

    try {
      loadingOnRemove.value = true;

      Logger.debug('[Magento Storefront]: useWishlist.removeItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery,
        customHeaders,
      });
      const currentPageParam = totalItems.value - 1 === pageSize.value && currentPage.value !== 1
        ? currentPage.value - 1
        : currentPage.value;

      const itemOnWishlist = product?.wishlist_item_id;

      const params: URLParams = {
        currentPage: currentPageParam,
        pageSize: pageSize.value,
      };

      if (sorting.value) {
        params.sort = sorting.value;
      }

      const { data } = await app.context.$vsf.$magento.api.removeProductsFromWishlist({
        id: '0',
        items: [itemOnWishlist.toString()],
        currentPage: currentPageParam,
        pageSize: pageSize.value,
        sort: createProductAttributeSortInput(sorting.value || ''),
        attributeCodes: attributeCodes.value,
      }, customQuery ?? { removeProductsFromWishlist: 'removeProductsFromWishlist' }, customHeaders);

      product.is_in_wishlist = !isInWishlist({ product });
      product.wishlist_item_id = null;

      Logger.debug('[Result]:', { data });
      error.value.removeItem = null;

      wishlistStore.$patch((state) => {
        state.wishlist = data?.removeProductsFromWishlist?.wishlist ?? {};
        state.totalItems = data?.removeProductsFromWishlist?.wishlist?.items_count ?? 0;
      });

      setQueryParams(params);
      wishlistStore.setCurrentPage(currentPageParam);
    } catch (err) {
      error.value.removeItem = err;
      Logger.error('useWishlist/removeItem', err);
    } finally {
      loadingOnRemove.value = false;

      sendNotification({
        id: Symbol('removed_from_wishlist'),
        type: 'success',
        message: '',
        icon: 'success',
        persist: false,
        title: app.i18n.t(
          'Removed from wishlist.'
        ) as string,
      });
    }
  };

  const loadItemsCount = async (): Promise<number | null> => {
    Logger.debug('useWishlist/wishlistItemsCount');
    const apiState = app.context.$vsf.$magento.config.state;
    let itemsCount : number | null = null;

    try {
      loading.value = true;
      error.value.loadItemsCount = null;

      if (apiState.getCustomerToken()) {
        const { data } = await app.context.$vsf.$magento.api.wishlistItemsCount();

        Logger.debug('[Result]:', { data });
        const loadedWishlist : Wishlist[] = data?.customer?.wishlists ?? [];
        itemsCount = calculateWishlistTotal(loadedWishlist);
        wishlistStore.$patch((state) => {
          state.wishlist.items_count = itemsCount;
        });
      }
    } catch (err) {
      error.value.loadItemsCount = err;
      Logger.error('useWishlist/wishlistItemsCount', err);
    } finally {
      loading.value = false;
    }

    return itemsCount;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({ product, customQuery, customHeaders }: UseWishlistAddItemParams) => {
    Logger.debug('useWishlist/addItem', product);

    try {
      loading.value = true;
      Logger.debug('[Magento Storefront]: useWishlist.addItem params->', {
        currentWishlist: wishlistStore.wishlist,
        product,
        customQuery,
        customHeaders,
      });

      if (!wishlistStore.wishlist) {
        await load({});
      }

      const itemOnWishlist = isInWishlist({ product })

      if (itemOnWishlist) {
        return;
      }

      if (!app.$vsf.$magento.config.state.getCustomerToken()) {
        Logger.error('Need to be authenticated to add a product to wishlist');
      }

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      switch (product.__typename) {
        case 'VirtualProduct':
        case 'DownloadableProduct':
        case 'GroupedProduct':
        case 'GiftCard':

        case 'SimpleProduct': {
          const { data } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
            }],
          }, customQuery, customHeaders);

          Logger.debug('[Result]:', { data });

          wishlistStore.$patch((state) => {
            state.wishlist = data?.addProductsToWishlist?.wishlist ?? {};

            const item = state.wishlist?.items_v2?.items?.find(item => item.product.sku === product.sku);
            const itemId = item?.id || null;
            product.wishlist_item_id = itemId;
          });

          product.is_in_wishlist = !isInWishlist({ product });

          break;
        }

        case 'ConfigurableProduct': {
          const variantSku = product.configurable_product_options_selection?.variant?.sku || product.sku;
          const { data: configurableProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: variantSku,
              quantity: 1,
              parent_sku: product.sku,
            }],
          }, customQuery, customHeaders);

          Logger.debug('[Result]:', { data: configurableProductData });

          wishlistStore.$patch((state) => {
            state.wishlist = configurableProductData?.addProductsToWishlist?.wishlist ?? {};

            const item = state.wishlist?.items_v2?.items?.find(item => item.product.sku === variantSku);
            const itemId = item?.id || null;
            product.wishlist_item_id = itemId;
          });

          product.is_in_wishlist = !isInWishlist({ product });

          break;
        }

        case 'BundleProduct': {
          const { data: bundleProductData } = await app.context.$vsf.$magento.api.addProductToWishList({
            id: '0',
            items: [{
              sku: product.sku,
              quantity: 1,
              entered_options: [],
            }],
          }, customQuery, customHeaders);

          Logger.debug('[Result]:', { data: bundleProductData });

          wishlistStore.$patch((state) => {
            state.wishlist = bundleProductData?.addProductsToWishlist?.wishlist ?? {};

            const item = state.wishlist?.items_v2?.items?.find(item => item.product.sku === product.sku);
            const itemId = item?.id || null;
            product.wishlist_item_id = itemId;
          });

          product.is_in_wishlist = !isInWishlist({ product });

          break;
        }

        default:
          // @ts-ignore
          // eslint-disable-next-line no-underscore-dangle
          Logger.error(`Product Type ${product.__typename} not supported in add to wishlist yet`);
      }
    } catch (err) {
      error.value.addItem = err;
      Logger.error('useWishlist/addItem', err);
    } finally {
      loading.value = false;

      sendNotification({
        id: Symbol('added_to_wishlist'),
        message: '',
        type: 'success',
        action: {
          text: app.i18n.t('Go to my wishlist') as string,
          onClick: () => router.push(localeRoute({ name: 'customer-my-wishlist' })),
        },
        icon: 'success',
        persist: false,
        title: app.i18n.t(
          'Added to wishlist.'
        ) as string,
      });
    }
  };

  const clear = async ({ currentWishlist }) => {
    Logger.debug('useWishlist/clear');

    try {
      const { data } : {
        data: { user_errors }
      } = await mutate<clearWishlistResult>(clearWishlistGql, { wishlistId: currentWishlist.id });

      Logger.debug('[Result]:', { data });
      error.value.removeItem = null;

      if (!data.user_errors) {
        wishlistStore.$patch((state) => {
          state.wishlist = {};
        })
      }
    } catch (err) {
      error.value.clear = err;
      Logger.error('useWishlist/clear', err);
    } finally {
      loading.value = false;
    }
  };

  const afterAddingWishlistItemToCart = (
    { product, cartError }: UseWishlistAfterAddingWishlistItemToCartParams,
  ) => {
    Logger.debug('useWishlist/afterAddingItemToCart', product);

    if (!isInWishlist({ product })) return;

    try {
      if (cartError?.message) {
        sendNotification({
          id: Symbol('product_added_to_cart_from_wishlist_error'),
          message: cartError.message,
          type: 'danger',
          icon: 'cross',
          persist: false,
          title: 'Wishlist error',
        });
      } else {
        removeItem({ product })
          .then(() => {
            sendNotification({
              id: Symbol('product_added_to_cart_from_wishlist'),
              message: app.i18n.t(
                'You added {product} to your shopping cart.',
                { product: product.name },
              ) as string,
              type: 'success',
              icon: 'check',
              persist: false,
              title: 'Wishlist',
            });
          });
      }
    } catch (err) {
      error.value.afterAddingWishlistItemToCart = err;
      Logger.error('useWishlist/afterAddingWishlistItemToCart', err);
    }
  };

  const addOrRemoveItem = async ({ product, customQuery, customHeaders }: UseWishlistAddItemParams) => {
    await (isInWishlist({ product: product })
        ? removeItem({ product, customQuery, customHeaders })
        : addItem({ product, customQuery, customHeaders })
    );
  };

  return {
    loadItemsCount,
    isInWishlist,
    addItem,
    load,
    removeItem,
    clear,
    setWishlist,
    afterAddingWishlistItemToCart,
    addOrRemoveItem,
    loading: readonly(loading),
    loadingOnRemove: readonly(loadingOnRemove),
    error: readonly(error),
  };
}

export default useWishlist;
export * from './useWishlist';
