import { Middleware } from '@nuxt/types';

const homepageResolver: Middleware = async (context) => {
  if (context.res) {
    context.res.setHeader('Surrogate-Control', context.$config.fastly.surrogateControl)
    context.res.setHeader('X-Magento-Tags', 'CP_home CONTENT_PAGE');
  }
};

export default homepageResolver;
