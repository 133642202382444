
















import { defineComponent, ref } from '@nuxtjs/composition-api';
import UserShippingPickupLocationItem from './UserShippingPickupLocationItem.vue';

interface Store {
  id: number;
  name: string;
  address: string;
  workingHours: string;
  latitude: number;
  longitude: number;
  showDetails?: boolean;
}

const PICKUP_LOCATIONS: Store[] = [
  { 
    id: 1, 
    name: 'London', 
    address: '3 St James’s Street, London, SW1A 1EG',
    workingHours: '10am - 7pm', 
    latitude: 51.5054865, 
    longitude: -0.1377456,
    showDetails: false,
  },
  {
    id: 2,
    name: 'Basingstoke',
    address: 'Hamilton Close, Basingstoke, RG21 6YB',
    workingHours: '10am - 6pm', 
    latitude: 51.275404, 
    longitude: -1.106953,
    showDetails: false,
  },
]

export default defineComponent({
  name: 'UserShippingPickupLocationList',
  components: {
    UserShippingPickupLocationItem,
  },
  setup() {
    // This should be replaced with actual data, 
    // either from props, computed properties, or an API call
    const locations = ref<Store[]>(PICKUP_LOCATIONS);

    return {
      locations,
    };
  },
});
