import { HybrisCartGetters, HybrisCartItem } from '@hybris/types';
import { ProductGroupsEnum } from '~/modules/checkout/getters/cartGettersExtended';

export const getItemQty = (product: HybrisCartItem): number => product.quantity;

/**
 * `getItemCaseOrderUnit` function returns the Case Order Unit for the cart item.
 */
export const getItemCaseOrderUnit = (cartItem: HybrisCartItem): number => cartItem.numberPerCase;

/**
 * `getItemQtyStep` function returns the quantity step for the cart item.
 *  - If the product is INBOND, the step is equal to the Case Order Unit.
 *  - If the product is DELIVERY and sell by case only, the step is equal to the Case Order Unit.
 *  - If the product is DELIVERY and sell by case only is set to `false`, the step is equal to 1.
 *  - If the product is VOUCHERS or EVENTS, the step is equal to 1.
 */
export const getItemQtyStep = (cartItem: HybrisCartItem, group: `${ProductGroupsEnum}`): number => {
  const INITIAL_QTY_STEP = 1;
  const caseQty = getItemCaseOrderUnit(cartItem);

  const qtyStepMap = {
    [ProductGroupsEnum.DEFAULT]: INITIAL_QTY_STEP,
    [ProductGroupsEnum.INBOND]: caseQty,
    [ProductGroupsEnum.EVENTS]: INITIAL_QTY_STEP,
    [ProductGroupsEnum.VOUCHERS]: INITIAL_QTY_STEP,
  };

  return qtyStepMap[group] ?? INITIAL_QTY_STEP;
}

const cartGetters: HybrisCartGetters = {
  getItemQty,
  getItemQtyStep,
};

export default cartGetters;
