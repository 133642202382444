









import {
  computed,
  defineComponent,
  watch,
  onMounted,
} from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import ActionItem from '@theme/components/atoms/ActionItem.vue';

export default defineComponent({
  name: 'ActionItemWishlist',
  components: {
    ActionItem,
  },
  setup() {
    const wishlistStore = useWishlistStore();
    const { isAuthenticated } = useUser();
    const { loadItemsCount } = useWishlist();
    const qty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    watch(isAuthenticated, (newValue: boolean) => {
      if (newValue) {
        loadItemsCount();
      } else {
        wishlistStore.$patch((state) => {
          state.wishlist = {};
        });
      }
    });

    onMounted(() => {
      loadItemsCount();
    });

    return {
      qty,
    };
  },
});
